import React from 'react'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from './data-analytics.stc'
import PropTypes from "prop-types"
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from "gatsby"
import Button from "../../../components/shared/button";

//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    // const servicesQueryData = useStaticQuery(graphql `
    //     query ServicesAiDataQuery {
    //         servicesaidataJson(id: {eq: "ai-content"}) {
    //             aiServices {
    //                 title
    //                 classId
    //                 link
    //                 description
    //                 tableHeader
    //                 tableData
    //                 image {
    //                     childImageSharp {
    //                         fluid(maxWidth: 960, quality: 100) {
    //                             ...GatsbyImageSharpFluid_withWebp
    //                         }
    //                     }
    //                 } 
    //             }
    //         }
    //     }    
    // `)
    // const serviceData = servicesQueryData.servicesaidataJson;
    // const aiServices = serviceData.aiServices;
    // console.log(serviceData.aiServices)

    // const aiContent = aiServices.map((aiService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{color: "black"}} href={aiService.link}>{aiService.title}</a>
    //     </div>
    // );

    // const content = aiServices.map((aiService, index) =>
    //     <div className="row services" key={index} id={aiService.classId}>
    //         <div className="col-2 offset-1">
    //             <SectionInner  style={{paddingRight: "10vh"}}>
    //                 <SectionTitleWrap>
    //                     <Heading {...HeadingStyleServies}>{aiService.title}</Heading>
    //                     {aiService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
    //                 </SectionTitleWrap>
    //             </SectionInner>
    //         </div>
    //         <div className="col-2">
    //             <SectionInner>
    //                 {aiService.image ? 
    //                 <Fade right>
    //                     <img src={aiService.image.childImageSharp.fluid.src} alt={aiService.title}/>
    //                 </Fade>
    //                 :
    //                 <Fade right>
    //                     <SectionTitleWrap>
    //                         {aiService.tableHeader && <Heading {...SubHeadingStyle}>{aiService.tableHeader}</Heading> }
    //                         <div className="row">
    //                             {aiService.tableData.map((textData, i) => 
    //                                 <div className="col-1" key={`story-text-${i}`}>
    //                                     <Text {...Paragraph}>{textData}</Text>
    //                                 </div>)
    //                             }
    //                         </div>
    //                     </SectionTitleWrap>
    //                 </Fade>
    //                 }

    //             </SectionInner>
    //         </div>
    //     </div>
    // );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading {...HeadingStyle}>Data Analytics</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage src={"../../../../src/data/images/BigData.jpeg"} alt="ai header"/>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ marginTop: 50 }}>
                    <SectionTitleWrap className="more-companies">
                        <Heading {...HeadingStyleServies}>More Companies Count
                            on Data <br></br> To Power Their Organisations</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h4 {...HeadingStyleServies}>Unifying Data</h4>
                        <Text {...Paragraph}>
                            Data will live in disparate systems because organisations utilise an array of platforms, systems and services.
                        </Text>
                        <Text {...Paragraph}>
                            Extracting, transforming and loading data into one unified location source for analytics, data sharing, data science, machine learning and prediction is one of the many data hurdles every large organisation faces today.
                        </Text>
                        <Button
                            onClick={() => { navigate("/contact") }}
                            layout={2}
                            style={{ height: "100%", margin: "auto" }}
                        >
                            Learn More
                            <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 100 }}>
                    <SectionTitleWrap className="data-warehouses">
                        <Heading {...HeadingStyleServies}>Data Warehouses <br></br> Data Lakehouses</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            Data Systems and Architectures
                        </h3>
                        <Text {...Paragraph}>
                            Today's best in class data systems involve being cloud first, separating compute and storage and abstracting data from source systems.
                        </Text>
                        <Text {...Paragraph}>
                            Organisations build Data Warehouses or Data Lakehouses to store and centralise massive amounts of data across their organisation.
                            The goal of centralising data is to perform fast analytics on data that is as close to real-time as possible to drive decision making for analysts and business units down to the lowest level employee.
                        </Text>
                        <Button
                            onClick={() => { navigate("/contact") }}
                            layout={2}
                            style={{ height: "100%", margin: "auto", background: "blue" }}
                        >
                            Contact Us
                            <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft style={{ paddingBottom: 140 }}>
                    <SectionTitleWrap className="setup-data">
                        <Heading {...HeadingStyleServies}>Setup and Data Pipelines</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <h3>
                            Data Wrangling
                        </h3>
                        <Text {...Paragraph}>
                            Significant efforts are made to setup the Organisation's ETL layer commonly known as Extract, Transform and Load.
                        </Text>
                        <Text {...Paragraph}>
                            ETL exists because in larger organisations, data lives in siloed source systems.
                            The schema, format, fields, data structures may be incomparable.
                            The first step is to extract the data into a staging platform where transforms can be applied.
                        </Text>
                        <Text {...Paragraph}>
                            Once data transforms have been completed, the data can be loaded into a data warehouse for various activities some of which could involve machine learning and predictive analysis.
                        </Text>
                        <Button
                            onClick={() => { navigate("/contact") }}
                            layout={2}
                            style={{ height: "100%", margin: "auto", background: "orange" }}
                        >
                            Learn Why
                            <i className="ti-arrow-right"></i>
                        </Button>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>


        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    aiServiceLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

