import styled from 'styled-components';
import {device} from '../../../theme'


export const AboutSectionWrap = styled.div `
    padding-top: 160px;
    background-color: black;

    .section-pt-md{
        padding-top: 70px;
    }
    .col-1.offset-custom{
        margin-left: 5vw;
    }
    .services{
        padding-top: 15vh;
        padding-bottom: 15vw;
        background-color: white;
        align-items: center;
    }
    .service-top-section{
        margin-bottom: 20vh;
    }
`;

export const SectionInnerLeft = styled.div `
    width: 50%;
    padding-left: 10vw;
    padding-right: 2vw;
    padding-bottom: 2vw;

    @media ${device.largest}{
        margin-bottom: 3vw;
        .setup-data {
            margin-bottom: 5vw;
        }
    }
    @media ${device.xlarge}{
        .data-warehouses {
            margin-bottom: 6vw;
        }
        .setup-data {
            margin-bottom: 7vw;
        }
    }


    @media ${device.medium}{
        .more-companies {
            margin-bottom: 24vw;
        }
        .data-warehouses {
            margin-bottom: 44vw;
        }
        .setup-data {
            margin-bottom: 37vw;
        }
    }

    @media ${device.small}{
        width: 100%;
        margin-bottom: 4vw;
        .more-companies {
            margin-bottom: 20vw;
        }
        .data-warehouses {
            margin-bottom: -10vw;
        }
        .setup-data {
            margin-bottom: -24vw;
        }
    }
`;

export const SectionInnerRight = styled.div `
    width: 50%;
    padding-right: 10vw;
    @media ${device.small}{
        width: 100%;
        padding-left: 10vw;
    }
`;