import React from "react"
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Section from '../../containers/services/data-analytics'

export default () => (
    <Layout headerLayout={2}>
        {/* TODO: Data-analytics descriptions */}
        <SEO title="Atlasopen - World Class AI & Machine Learning Solutions" description="Automate to innovate. When you need world class Artificial Intelligence and Machine Learning capabilities we can provide services to help you create AI and ML technologies"/>
        {/*<Banner/>*/}
        {/*<About/>*/}
        <Section/>
        {/*<JoinOurTeam/>*/}
        {/*<Testimonial/>*/}
    </Layout>
)

